<script lang="ts" setup>
	import { useLogin } from '../stores/states';
	import { useCarroCompras } from '../stores/carrocompras';
	import type { ResponseIndex } from '../types/responses';
	const config = useRuntimeConfig();
	useSeoMeta({
		title: `INICIO | ${config.public.razon_social}`,
	})
	const uselogin = useLogin();
	const usecarro = useCarroCompras();
	


	const { data } = await useAsyncData<ResponseIndex>('indexController', () =>
		$fetch('/sliders_index', {
			baseURL: config.public['apibase'],
			method: 'POST',
			headers: {
				Authorization: `Bearer ${uselogin.token}`,
			},
			body: {
				productos: usecarro.carrito.map(e => ({
					codigo: e.codigo,
					cantidad: e.cantidad,
				})),
			}, 
		})
	);

</script>
<template>
	<div
		class="bg-fondoIndex px-0 pt-0"
	>
	<div
		class="container-web"
	>
  		<TheCarousel 
			:imagenes="data.banner_principal"
		/>
		
		<IndexBannerSeguridad />

		<template v-if="data.sliders">

			<template v-for="slider in data.sliders" :key="slider">
                <template v-if="!slider.tipo || slider.tipo == 1">
                    <div class="slider-container">
                        <IndexSlidersProductos1
                        :titulo="slider.titulo"
                        :productos="slider.items"
                        :link-ver-mas="slider?.link"
                        />
                    </div>
                </template>
                <template v-else-if="slider.tipo == 2">
                    
                    <div class="slider-container">
                        <IndexSlidersProductos2
                        :titulo="slider.titulo"
                        :productos="slider.items"
                        :link-ver-mas="slider?.link"
                        :img-slider="slider.imagen"
                        />
                    </div>
                </template>
                <template v-else-if="slider.tipo == 3">
                    <div class="slider-container">
                        <IndexSlidersProductos3
                        :titulo="slider.titulo"
                        :productos="slider.items"
                        :link-ver-mas="slider?.link"
                        :img-slider="slider.imagen"
                        />
                    </div>
                </template>
                <template v-else-if="slider.tipo == 4">
                    <div class="slider-container">
                        <IndexSlidersProductos4
                        :titulo="slider.titulo"
                        :productos="slider.items"
                        :link-ver-mas="slider?.link"
                        :img-slider="slider.imagen"
                        />
                    </div>
                </template>
                <template v-else-if="slider.tipo == 'banner'">
                    <NuxtLink :to="slider.link">
                        <picture>
                            <source class="lg:rounded-lg" :srcset="slider.imagen_lg" media="(min-width: 768px)" >
                            <img class="lg:rounded-lg" loading="lazy" :src="slider.imagen_xs" alt="banner secundario 1" >
                        </picture>
                    </NuxtLink>
                </template>
                <template v-else-if="slider.tipo == 'categorias'">
                    <IndexSlidersCategorias :categorias="slider.items" />
                </template>
                <template v-else-if="slider.tipo == 'marcas'">
                    <IndexGaleriaMarcas :titulo="slider.titulo" :marcas="slider.items" /> 
                </template>
			</template>
		</template>
	</div>

</div>
</template>
<style scoped>
picture > img {
	width: 100%;
	height: auto;
	margin: 8 auto;
}
picture > source {
	width: 100%;
	object-position: center;
	object-fit: cover;
}
.slider-container {
	margin: 4px auto;
}

@media(min-with:768px) {
	picture > img {
		width: 110%;
		height: auto;
		margin: 50px auto;
		margin-inline-start: -5%;
		margin-inline-end: -5%;
	}
}
@media(min-width:1024px) {
	.slider-container {
		margin: 80px auto;
	}
}
@media (min-width: 1440px) {
	picture > img {
		width: 100%;
		height: auto;
		margin-inline-start: 0%;
		margin-inline-end: 0%;
	}
}
</style>