<script lang="ts" setup>
import type { Banner } from "../types/products";
const props = defineProps<{
	imagenes: Banner[];
}>();

const currentImage = ref(0);
const isPaused = ref(false)
const sliderContainer = ref<HTMLDivElement>()
const nextSlide = () => {
	nextTick(() => {
		if (
		sliderContainer.value?.scrollLeft >=
		sliderContainer.value?.scrollWidth - sliderContainer.value?.offsetWidth
		) {
			sliderContainer.value.scrollLeft = 0;
			currentImage.value = 0;
			return
		}
		currentImage.value += 1;
		sliderContainer.value?.scrollBy({
			top: 0,
			left: sliderContainer.value.offsetWidth,
			behavior: "smooth",
		});

	})
};
const prevSlide = () => {

		const anchoContenedor = sliderContainer.value.scrollWidth
	
		const posicionImg = sliderContainer.value.scrollLeft / props.imagenes.length
 
		currentImage.value = sliderContainer.value.scrollLeft / posicionImg

		// Verificar si estamos en el principio del contenido
		// Si estamos al principio, mover al final del contenido
		if (sliderContainer.value.scrollLeft == 0) {
			sliderContainer.value.scrollLeft = sliderContainer.value.scrollWidth - sliderContainer.value.offsetWidth;
			currentImage.value = props.imagenes.length - 1
			return
		}
		
		sliderContainer.value?.scrollBy({
			top: 0,
			left: - sliderContainer.value.offsetWidth,
			behavior: "smooth",
		});
		currentImage.value = currentImage.value - 1
		
};

let xStart = null;
const touchStart = (e) => {
	xStart = e.touches[0].clientX;
};
const touchMove = (e) => {
	if (!xStart) {
		return false;
	}
	const xDiff = xStart - e.touches[0].clientX;
	if (Math.abs(xDiff)) {
		xDiff > 0 ? nextSlide() : prevSlide();
		xStart = null;
	}
};

onMounted(() => {
	if (!isPaused.value) {
		setInterval(() => {
			if (!isPaused.value) {
				nextSlide();
			}
		}, 7000);
	}

	const slider = document.getElementById("slider");
	slider.addEventListener("touchstart", touchStart, false);
	slider.addEventListener("touchmove", touchMove, false);
});
</script>
<template>
	<div class="relative">
		
		<div class="relative flex items-center gap-3" @click="isPaused = true">
			<button class="hidden lg:block absolute top-2/4 left-12 z-[1] text-[#FFF] rounded-full bg-light-secondaryDark -translate-x-2/4 p-3 cursor-pointer" @click="prevSlide">
				<ClientOnly>
					<svg
						id="Capa_1"
						height="30px"
						width="30px"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						viewBox="0 0 185.343 185.343"
						xml:space="preserve"
						fill="white"
						stroke="#000000"
						transform="rotate(180)"
					>
						<g id="SVGRepo_bgCarrier" stroke-width="0" />

						<g
							id="SVGRepo_tracerCarrier"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>

						<g id="SVGRepo_iconCarrier">
							<g>
								<g>
									<path
										style="fill: white"
										d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
									/>
								</g>
							</g>
						</g>
					</svg>
				</ClientOnly>
			</button>
			<div id= "slider" ref="sliderContainer" class="relative flex w-auto overflow-x-hidden snap-mandatory snap-x scroll-smooth mx-auto hide-scroll hide-scroll" style="max-width: 1440px">
				<template v-for="imagen,index in imagenes" :key="index">
					<NuxtLink class="inline-block snap-center snap-always min-w-full" :to="imagen.link" :target="imagen.link_target">
						<img
							class="w-full h-auto overflow-y-hidden flex-shrink-0 lg:hidden"
							:src="imagen.imagen_xs"
							loading="lazy"
						>
						<img
							class="hidden lg:inline-block lg:overflow-y-auto lg:w-full md:h-48 lg:h-[225px] xl:h-[300px] lg:max-w-[1600px] lg:mx-auto lg:object-center lg:object-cover lg:flex-shrink-0"
							:src="imagen.imagen_lg"
							loading="lazy"
						>
					</NuxtLink>
				</template>
			</div>

			<button class="hidden lg:block absolute top-2/4 right-1 z-[1] text-[#FFF] rounded-full bg-light-secondaryDark -translate-x-2/4 p-3 cursor-pointer" @click="nextSlide">
				<ClientOnly>
					<svg
						id="Capa_1"
						height="30px"
						width="30px"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						viewBox="0 0 185.343 185.343"
						xml:space="preserve"
					>
						<g>
							<g>
								<path
									style="fill: white"
									d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175
								l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934
								c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
								/>
							</g>
						</g>
					</svg>
				</ClientOnly>
			</button>
		</div>
		<div class="absolute z-10 left-2/4 bottom-2 flex gap-2 p-1 -translate-x-2/4 opacity-50 bg-[gray]">
			<div
				v-for="(image, index) in imagenes"
				:key="index"
				class="w-1 h-1 bg-[#fff] rounded-full lg:w-2 lg:h-2"
				:class="{ 'resalt': currentImage === index }"	
			/>
		</div>
	</div>
</template>

<style scoped>
.resalt {
 background-color: #A23;
}
</style>
